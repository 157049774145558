import { HttpUrlEncodingCodec } from '@angular/common/http';

// https://github.com/angular/angular/issues/11058
export default class CustomHttpUrlEncodingCodec extends HttpUrlEncodingCodec {
    encodeKey(key: string): string {
        return encodeURIComponent(key);
    }

    encodeValue(value: string): string {
        return encodeURIComponent(value);
    }
}